import { React } from "react";

export default function FinalStep({ setSelection }) {
  return (
    <>
      <div className="content complete">
        <div className="notification">
          <h1>Thank you</h1>
          <div className="notice-success">
            Your images have been submitted. We will get back to you with an estimate as soon as possible.
          </div>
        </div>
      </div>
    </>
  );
};