import { React } from "react";

export default function ErrorStep({ setSelection }) {
  return (
    <>
      <div className="content complete">
        <div className="notification failse">
          <h1>Sorry</h1>
          <div className="notice-failse">
            Some thing went wrong. Please try again later.
          </div>
        </div>
      </div>
      <div className="footer">
          <button className="btn btn-primary f-width" onClick={() => setSelection("firstStep")} >Try Again</button>
      </div>
    </>
  );
};