import { React } from "react";
import sampleImage from '../images/website-hero_figma-preview.jpg';

export default function HomePage({ setSelection }) {
  const urlParams = new URLSearchParams(window.location.search);
  const claimId = urlParams.get('claimId');

  return (
    <>
      <div className="content">
        <div className="notification home">
          <h1>Welcome to Trueclaim</h1>
          <p>Take photos of the damage to your vehicle</p>
          <div className="content-home">
            <p>Please make sure your photos have:</p>
            <ul>
              <li className="good-light">Good lighting</li>
              <li className="clear-view">Clear view of the damage</li>
            </ul>
          </div>
          <div className="r-8"><img src={sampleImage} alt="sample" /></div>
        </div>
      </div>
      <div className="footer">
          <button className="btn btn-primary f-width" disabled={claimId ? false : true} onClick={() => setSelection("firstStep")} >Start</button>
      </div>
    </>
  );
};