import { useState } from "react";
import logo from './logo.png';
import HomePage from './components/HomePage';
import FirstStep from './components/FirstStep';
import FinalStep from './components/FinalStep';
import ErrorStep from './components/ErrorStep';
import './App.css';

function App() {
  const [selection, setSelection] = useState("main");

  return (
    <>
      <div className="header">
        <img src={logo} alt="logo" />
      </div>

      {selection === "main" && (
        <HomePage setSelection={setSelection}/>
      )}

      {selection === "firstStep" && (
        <FirstStep setSelection={setSelection}/>
      )}

      {selection === "finalStep" && (
        <FinalStep setSelection={setSelection}/>
      )}

      {selection === "errorStep" && (
        <ErrorStep setSelection={setSelection}/>
      )}

    </>
  );
}

export default App;
